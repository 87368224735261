import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_UPDATE, UPDATE_ERROR, UPDATE_SUCCESS, USER_NOTIFICATIONS, CHANGE_VIEWMODE } from '../actions/user';
import { AUTH_LOGOUT } from '@/utils/store/actions/auth';
import apiCall from '@/utils/api_call';
import { ApiDefaults } from '@/utils/api_call';
import { FAV_UPDATE } from '../actions/favorites';
// import { FAV_UPDATE } from '../actions/favorites';

const state = {status: '', profile: {}, viewMode: 'light'};

const getters = {
	userStatus: (state) => state.status,
	getProfile: ( state ) => state.profile,
	getProfileImage: ( state ) => state.profile.image,
	isValidProfile: (state) => ( state.profile.firstName !== '' && state.profile.lastName !== '' ),
	isProfileLoaded: ( state ) => Object.hasOwnProperty.call(state.profile, 'firstName'),
	isSubscribed: ( state ) => Object.hasOwnProperty.call(state.profile, 'push_endpoint'),
	getLastLogin: function ( state ) {
		if ( !Object.hasOwnProperty.call(state.profile, 'last_login') ) {
			return null;
		}
		else {
			return new Date(state.profile.last_login.date);
		}
	},
	getViewMode: ( state ) => state.viewMode,
	isAdmin: (state) => state.profile.admin
};

const actions = {
	[ USER_REQUEST ]: ( {commit} ) => {
		commit(USER_REQUEST);

		let user = JSON.parse(localStorage.getItem('tmp-profile')),
			getData = new FormData();

		// getData.append('tx_c3json_jsondatauser[controller]','JsonDataUser');
		getData.append('tx_c3json_jsondatauser[action]','profile');
		getData.append('tx_c3json_jsondatauser[user]', user.uid );

		let apiParams = Object.assign({},
			ApiDefaults,
			{
				method: 'post',
				data: getData,
				params: {
					id: 1,
					type: 10
				}
			});

		apiCall(apiParams)
			.then(( response ) => {
				let profile = response.data.user;
				profile.fullName = profile.firstName + ' ' + profile.lastName;

				localStorage.setItem('tmp-profile', JSON.stringify(Object.assign({},profile,{token:user.token})));

				if( response.data.friends ) {
					commit('favorites/' + FAV_UPDATE , {target:'contacts', list:response.data.friends});
				}
				commit(USER_SUCCESS, profile);
			})
			.catch(() => {
				let profile = JSON.parse(localStorage.getItem('tmp-profile'));
				delete profile.username;
				commit(USER_SUCCESS, profile);
			});
	},
	[ USER_UPDATE ]: ( {commit, state}, profileSettings ) => {

		return new Promise(( resolve, reject ) => {
			let postData = new FormData(),
				user = state.profile.uid;
			postData.append('tx_c3json_jsondatauser[user][uid]', user);
			postData.append('tx_c3json_jsondatauser[action]', 'update');

			if ( typeof profileSettings.image !== 'undefined' ) {
				if( typeof profileSettings.image === 'string' ){
					postData.append('tx_c3json_jsondatauser[user][image]',profileSettings.image);
				}
				else {
					postData.append('image', profileSettings.image);
				}
				commit(USER_UPDATE);
			}

			if ( typeof profileSettings.darkMode !== 'undefined' )
				postData.append('dark_mode', profileSettings.darkMode);

			if ( typeof profileSettings.notifications !== 'undefined' )
				postData.append('notifications', profileSettings.notifications);


			if( Object.prototype.hasOwnProperty.call(profileSettings,'action') ){
				for( let [key,val] of Object.entries(profileSettings) ) {

					if( ['uid','fullName','action','image','token','profileImage','admin'].indexOf(key) === -1 ) {
						postData.append('tx_c3json_jsondatauser[user]['+key+']',val);
					}
				}
			}

			if ( typeof profileSettings.newPasswd !== 'undefined' ) {
				postData.append('tx_c3json_jsondatauser[user][new_password]', profileSettings.newPasswd);
				postData.append('tx_c3json_jsondatauser[user][old_password]', profileSettings.oldPasswd);
				// postData.append('passwort', profileSettings.newPasswd );
			}

			let apiParams = Object.assign({}, ApiDefaults,
				{
					method: 'post',
					params: {
						id: 1,
						type: 10
					},
					data: postData,
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});

			apiCall(apiParams)
				.then(( response ) => {
					if ( response.status === 200 || response.statusText === 'OK' ) {
						if ( response.data.status === 'OK' ) {
							let userData = response.data.user;
							userData.fullName = userData.firstName + ' ' + userData.lastName;
							commit(UPDATE_SUCCESS, userData);
							resolve(response.data);
						}
						else if ( response.data.status === 'ERROR' ) {
							commit(UPDATE_ERROR);
							reject(response.data);
						}
						// commit(UPDATE_SUCCESS, response.data.loggedInUser);
						// resolve(response.data.loggedInUser);
					}
				})
				.catch(( err ) => {

					commit(UPDATE_ERROR);
					reject(new Error('Profile update failed:' + err));
				});


		});

	}
};

const mutations = {
	[ USER_REQUEST ]: ( state ) => {
		state.status = 'loading';
	},
	[ USER_SUCCESS ]: ( state, resp ) => {
		state.profile = resp;
		state.status = 'success';
	},
	[ USER_ERROR ]: ( state ) => {
		state.status = 'error';
	},
	[ AUTH_LOGOUT ]: ( state ) => {
		state.profile = {};
		state.status = 'logged_out';
	},
	[ USER_UPDATE ]: ( state ) => {
		state.status = 'updating';
	},
	[ UPDATE_SUCCESS ]: ( state, newProfile ) => {
		state.status = 'success';
		if( newProfile.image === '' ) {
			state.profile.image = '';
		}

		state.profile = Object.assign(state.profile, newProfile);
	},
	[ UPDATE_ERROR ]: ( state ) => {
		state.status = 'error';
	},
	[ USER_NOTIFICATIONS ]: ( state ) => {
		state.profile.push_endpoint = true;
	},
	[ CHANGE_VIEWMODE ]: ( state ) => {
		state.viewMode = (state.viewMode === 'dark' ? 'light' : 'dark');
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
